'use client'

import MyImage from "lib/image"
import Link from "next/link"
import { useEffect, useState } from "react"
import { FaChevronCircleRight } from "react-icons/fa"

const cards = [
    {
        name: 'Matheran',
        image: 'uploads/5066767ed4144a1885d1f38cf964b63b.jpeg',
        link: '/forest-camping-in-matheran-mumbai'
    },
    {
        name: 'Alibaug',
        image: 'uploads/b6a8cb688575417b9f382ad81fdb291c.webp',
        link: '/alibag-premium-beach-camping'
    },
    {
        name: 'Bhandardara',
        image: 'uploads/c4b30dfdb6cb41a589cb8d3846df1cc4.jpeg',
        link: '/bhandardara-fireflies-dam-camping'
    },
    {
        name: 'Panshet',
        image: 'uploads/54cc1abf9f154d199a142eeb599bc93f.jpeg',
        link: '/triangle-tents-panshet-near-pune'
    },
    {
        name: 'Kamshet',
        image: 'uploads/5d54a7347db54b88bb91dcce49a7d6ea.jpeg',
        link: '/city/kamshet'
    },
]

export default function CricketCards() {
    const [isMonoton, setIsMonoton] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsMonoton((prev) => !prev);
        }, 400);
        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    return (
        <div className='mt-10' style={{ backgroundColor: '#B3E3EE' }}>
            <h3 className={`mt-3 pb-0 text-center text-3xl font-black text-[#2255A4]`} style={{ height: 45 }}>
                <span className={isMonoton ? "font-overcameoutline" : "font-overcamebold"}>Cricket Live</span>
            </h3>
            <p className="text-center pb-3 text-[#078BDC] font-medium">It's Time To Bleed Blue</p>
            <div className="flex overflow-x-scroll px-4 hide-scroll-bar py-4">
                <div className="flex flex-nowrap space-x-2">
                    {cards.map((card, index) => (
                        <Link key={index} href={card.link}>
                            <div className="h-56 md:h-64 w-[40vw] md:w-[18vw] rounded-xl overflow-hidden relative">
                                <MyImage src={card.image} layout="fill" className="object-cover" />
                                <div className="absolute bottom-0 left-0 w-full py-2 px-2 bg-gray-600 backdrop-filter backdrop-blur-lg bg-opacity-40 flex items-center justify-between">
                                    <h2 className="text-white text-sm font-medium">{card.name}</h2>
                                    <FaChevronCircleRight className="text-white" />
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}   
